
import { defineComponent } from 'vue';
const images = require.context('../assets/xtjw/', false, /\.png$/)
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default defineComponent({
  data() {
    return {
    }
  },
  methods: {
    imgUrl: (path: string) => {
      return images('./' + path)
    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
});
